<template>
  <v-row align="stretch">
    <v-col cols="12 h4-content mt-0">
      <h4>Google Analytics</h4>
      <span />
    </v-col>
    <v-col cols="12" class="text-center">
      {{ $t('settings.siteSettings.enterGoogleAnalytics') }}
    </v-col>
    <v-col cols="12">
      <v-text-field
        outlined
        v-model="myCompany.booker.ganalytics"
        label="ID Google Analytics"
      />
    </v-col>
    <v-col cols="12">
      <v-text-field
        outlined
        v-model="myCompany.booker.gaConversionId"
        label="Conversion ID"
      />
    </v-col>
    <v-col cols="12">
      <v-text-field
        outlined
        v-model="myCompany.booker.gaConversionLabel"
        label="Conversion Label"
      />
    </v-col>
    <!--    <v-col cols="12">-->
    <!--      <v-text-field-->
    <!--        outlined-->
    <!--        v-model="myCompany.vault.abstaturl"-->
    <!--        label="STAT URL"-->
    <!--      />-->
    <!--    </v-col>-->
    <!--    <v-col cols="12">-->
    <!--      <v-text-field-->
    <!--        outlined-->
    <!--        v-model="myCompany.vault.abstatkey"-->
    <!--        label="STAT KEY"-->
    <!--      />-->
    <!--    </v-col>-->
    <v-col cols="12 h4-content mt-0">
      <h4>Facebook Pixel</h4>
      <span />
    </v-col>
    <v-col cols="12" class="text-center">
      {{ $t('settings.siteSettings.enterFacebookPixel') }}
    </v-col>
    <v-col cols="12">
      <v-text-field
        outlined
        v-model="myCompany.booker.fbPixel"
        label="Facebook Pixel"
      />
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'stats',
  props: {
    myCompany: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.initStats()
  },
  methods: {
    initStats() {
      if (!this.myCompany.booker) {
        Vue.set(this.myCompany, 'booker', {})
      }

      if (!this.myCompany.booker.ganalytics) {
        Vue.set(this.myCompany.booker, 'ganalytics', '')
      }

      if (!this.myCompany.booker.gaConversionId) {
        Vue.set(this.myCompany.booker, 'gaConversionId', '')
      }

      if (!this.myCompany.booker.gaConversionLabel) {
        Vue.set(this.myCompany.booker, 'gaConversionLabel', '')
      }

      if (!this.myCompany.booker.fbPixel) {
        Vue.set(this.myCompany.booker, 'fbPixel', '')
      }

      if (!this.myCompany.vault) {
        Vue.set(this.myCompany, 'vault', {})
      }

      if (!this.myCompany.vault.abstaturl) {
        Vue.set(this.myCompany.vault, 'abstaturl', '')
      }

      if (!this.myCompany.vault.abstatkey) {
        Vue.set(this.myCompany.vault, 'abstatkey', '')
      }
    },
  },
}
</script>

<style scoped></style>
