<template>
  <div>
    <v-row align="stretch">
      <v-col cols="12 h4-content mt-0">
        <h4>Message général</h4>
        <span />
      </v-col>
      <v-col cols="12">
        <div>
          <RichEditor
            v-model="myCompany.booker.generalMessage"
            height="150px"
          />
        </div>
      </v-col>
    </v-row>

    <v-row align="stretch">
      <v-col cols="12 h4-content">
        <h4>Site principal</h4>
        <span />
      </v-col>
      <v-col cols="12">
        <div>
          <v-switch
            v-model="myCompany.booker.primarySiteLinkEnabled"
            inset
            label="Ajouter un lien vers votre site principal"
          />
        </div>
      </v-col>
      <v-col cols="12" v-if="myCompany.booker.primarySiteLinkEnabled">
        <div>
          <v-text-field
            v-model="myCompany.booker.primarySiteLink"
            label="Lien de votre site principal"
            outlined
            hide-details
          />
        </div>
      </v-col>
      <v-col cols="12" v-if="myCompany.booker.primarySiteLinkEnabled">
        <div>
          <MultilangTextField
            v-model="myCompany.booker.primarySiteLinkText"
            label="Texte du lien de retour"
          />
        </div>
      </v-col>
    </v-row>

    <v-row align="stretch">
      <v-col cols="12 h4-content">
        <h4>{{ $t('settings.siteSettings.maintenance') }}</h4>
        <span />
      </v-col>
      <v-col cols="12">
        <v-switch
          inset
          v-model="myCompany.booker.siteDisabled"
          :label="$t('settings.siteSettings.offlineSite')"
        />
        <div class="mt-6" v-if="myCompany.booker.siteDisabled">
          <strong>{{ $t('settings.siteSettings.message') }}</strong>
        </div>
        <div v-if="myCompany.booker.siteDisabled">
          <RichEditor v-model="myCompany.booker.siteDisabledMessage" />
        </div>
      </v-col>
    </v-row>

    <v-row align="stretch">
      <v-col cols="12 h4-content">
        <h4>{{ $t('settings.siteSettings.languageManagement') }}</h4>
        <span />
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col
            cols="12"
            sm="6"
            v-for="langKey in Object.keys(langs)"
            :key="langKey"
          >
            <v-switch
              inset
              v-model="myCompany.booker.langs[langKey]"
              :label="langs[langKey]"
            />
          </v-col>
        </v-row>

        <v-divider class="my-6 mx-6" />

        <v-select
          outlined
          v-model="myCompany.booker.defaultLang"
          :items="
            Object.keys(langs).map((langKey) => ({
              value: langKey,
              text: langs[langKey],
            }))
          "
          :label="$t('settings.siteSettings.substitutionLanguage')"
          disabled
        />
      </v-col>
    </v-row>

    <v-row align="stretch">
      <v-col cols="12 h4-content">
        <h4>{{ $t('settings.siteSettings.devise') }}</h4>
        <span />
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <v-select
              outlined
              v-model="myCompany.booker.mCurrency"
              :items="currencyOptions"
              :label="$t('settings.siteSettings.devise')"
            />
          </v-col>
          <v-col cols="12">
            <v-select
              outlined
              v-model="myCompany.booker.mCurrencyFormat"
              :items="currencyFormatOptions"
              :label="$t('settings.siteSettings.format')"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row align="stretch">
      <v-col cols="12 h4-content">
        <h4>{{ $t('settings.siteSettings.navigation') }}</h4>
        <span />
      </v-col>
      <v-col cols="12">
        <v-tabs v-model="tabNav" hide-slider>
          <v-tab>
            <div
              class="primary white--text pa-3 rounded font-weight-bold elevation-2"
              :class="{
                notSelectedTab: tabNav !== 0,
              }"
            >
              Mobile
            </div>
          </v-tab>
          <v-tab>
            <div
              class="primary white--text pa-3 rounded font-weight-bold elevation-2"
              :class="{
                notSelectedTab: tabNav !== 1,
              }"
            >
              Desktop
            </div>
          </v-tab>

          <v-tab-item :key="1">
            <v-row>
              <v-col cols="12" md="6" style="border-right: 1px solid #aaaaaa">
                <div class="ma-5">
                  <div class="text-center font-weight-bold">
                    {{ $t('settings.siteSettings.leftButton') }}
                  </div>
                  <div class="ma-3">
                    <v-select
                      outlined
                      v-model="
                        myCompany.booker.navigation.mobile.bottomNavLeftInfo
                          .name
                      "
                      :items="bottomBarActions"
                      item-value="name"
                      item-text="title"
                      :label="$t('settings.siteSettings.page')"
                      @change="changeBottomLeft"
                    >
                      <template slot="item" slot-scope="{ item, on, attrs }">
                        <template
                          v-if="
                            (item.name === 'gift' && !myCompany.booker.gift) ||
                            (item.name === 'eShop' &&
                              !myCompany.booker.products.active)
                          "
                        >
                          <v-list-item style="opacity: 0.5">
                            <v-list-item-content>
                              <v-list-item-title>
                                <v-icon>{{ item.icon }} </v-icon>
                                <span
                                  style="text-transform: capitalize"
                                  class="pl-3"
                                >
                                  {{ item.title }}
                                </span>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                        <template v-else>
                          <v-list-item
                            v-on="on"
                            v-bind="attrs"
                            @click="
                              myCompany.booker.navigation.mobile.bottomNavLeftInfo.name =
                                item.name
                            "
                          >
                            <v-list-item-content>
                              <v-list-item-title>
                                <v-icon>{{ item.icon }} </v-icon>
                                <span
                                  style="text-transform: capitalize"
                                  class="pl-3"
                                >
                                  {{ item.title }}
                                </span>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </template>
                    </v-select>

                    <multilang-text-field
                      v-model="
                        myCompany.booker.navigation.mobile.bottomNavLeftInfo
                          .title
                      "
                      :disabled="isDisabledLeft"
                      :label="$t('settings.siteSettings.changeTitle')"
                      class="my-5"
                    />

                    <div v-if="isDisabledLeft" style="opacity: 0.5">
                      <v-icon
                        v-for="icon in listIcons"
                        :key="icon"
                        class="ma-2"
                      >
                        mdi {{ icon }}
                      </v-icon>
                    </div>
                    <div v-else>
                      <v-icon
                        v-for="icon in listIcons"
                        :key="icon"
                        class="ma-2"
                        :class="
                          myCompany.booker.navigation.mobile.bottomNavLeftInfo
                            .icon === icon
                            ? 'grey lighten-2 pa-1 rounded-circle'
                            : {}
                        "
                        :large="
                          myCompany.booker.navigation.mobile.bottomNavLeftInfo
                            .icon === icon
                        "
                        @click="
                          myCompany.booker.navigation.mobile.bottomNavLeftInfo.icon =
                            icon
                        "
                      >
                        mdi {{ icon }}
                      </v-icon>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="6" style="border-left: 1px solid #aaaaaa">
                <div class="ma-5">
                  <div class="text-center font-weight-bold">
                    {{ $t('settings.siteSettings.rightButton') }}
                  </div>
                  <div class="ma-3">
                    <v-select
                      outlined
                      v-model="
                        myCompany.booker.navigation.mobile.bottomNavRightInfo
                          .name
                      "
                      :items="bottomBarActions"
                      item-value="name"
                      item-text="title"
                      :label="$t('settings.siteSettings.page')"
                      @change="changeBottomRight"
                    >
                      <template slot="item" slot-scope="{ item, on, attrs }">
                        <template
                          v-if="
                            (item.name === 'gift' && !myCompany.booker.gift) ||
                            (item.name === 'eShop' &&
                              !myCompany.booker.products.active)
                          "
                        >
                          <v-list-item style="opacity: 0.5">
                            <v-list-item-content>
                              <v-list-item-title>
                                <v-icon>{{ item.icon }} </v-icon>
                                <span
                                  style="text-transform: capitalize"
                                  class="pl-3"
                                >
                                  {{ item.title }}
                                </span>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                        <template v-else>
                          <v-list-item
                            v-on="on"
                            v-bind="attrs"
                            @click="
                              myCompany.booker.navigation.mobile.bottomNavRightInfo.name =
                                item.name
                            "
                          >
                            <v-list-item-content>
                              <v-list-item-title>
                                <v-icon>{{ item.icon }} </v-icon>
                                <span
                                  style="text-transform: capitalize"
                                  class="pl-3"
                                >
                                  {{ item.title }}
                                </span>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </template>
                    </v-select>

                    <multilang-text-field
                      v-model="
                        myCompany.booker.navigation.mobile.bottomNavRightInfo
                          .title
                      "
                      :disabled="isDisabledRight"
                      :label="$t('settings.siteSettings.changeTitle')"
                      class="my-5"
                    />

                    <div v-if="isDisabledRight" style="opacity: 0.5">
                      <v-icon
                        v-for="icon in listIcons"
                        :key="icon"
                        class="ma-2"
                      >
                        mdi {{ icon }}
                      </v-icon>
                    </div>
                    <div v-else>
                      <v-icon
                        v-for="icon in listIcons"
                        :key="icon"
                        class="ma-2"
                        :class="
                          myCompany.booker.navigation.mobile.bottomNavRightInfo
                            .icon === icon
                            ? 'grey lighten-2 pa-1 rounded-circle'
                            : {}
                        "
                        :large="
                          myCompany.booker.navigation.mobile.bottomNavRightInfo
                            .icon === icon
                        "
                        @click="
                          myCompany.booker.navigation.mobile.bottomNavRightInfo.icon =
                            icon
                        "
                      >
                        mdi {{ icon }}
                      </v-icon>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>

            <div class="text-center mt-5">
              <v-btn text color="red lighten-2" @click="resetMobile">
                {{ $t('settings.siteSettings.reset') }}
              </v-btn>
            </div>
          </v-tab-item>

          <v-tab-item :key="2">
            <div class="ma-5">
              <div class="font-weight-bold">
                {{ $t('settings.siteSettings.editMenuTitles') }} :
              </div>
              <div class="ma-3">
                <multilang-text-field
                  v-model="myCompany.booker.navigation.desktop.services"
                  class="my-4"
                  :label="$t('settings.siteSettings.servicesPage')"
                />
                <multilang-text-field
                  v-model="myCompany.booker.navigation.desktop.boutique"
                  class="my-4"
                  :disabled="!myCompany.booker.products.active"
                  :label="$t('settings.siteSettings.shopPage')"
                />
                <multilang-text-field
                  v-model="myCompany.booker.navigation.desktop.gift"
                  class="my-4"
                  :disabled="!myCompany.booker.gift"
                  :label="$t('settings.siteSettings.giftPage')"
                />
                <multilang-text-field
                  v-model="myCompany.booker.navigation.desktop.magazine"
                  class="my-4"
                  :label="$t('settings.siteSettings.magazinePage')"
                />
                <multilang-text-field
                  v-model="myCompany.booker.navigation.desktop.contact"
                  class="my-4"
                  :label="$t('settings.siteSettings.contactPage')"
                />
              </div>
              <div class="text-center">
                <v-btn text color="red lighten-2" @click="resetDesktop">
                  {{ $t('settings.siteSettings.reset') }}
                </v-btn>
              </div>
            </div>
          </v-tab-item>
        </v-tabs>
      </v-col>
      <v-col cols="12">
        <v-simple-table
          style="border: 1px solid #ccc"
          dense
          v-if="!addCustomMenuItem"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th>Order</th>
                <th>Texte</th>
                <th>Lien</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="!myCompany.booker.customMenuItems.length">
                <td colspan="4" class="text-center py-6">
                  Pas encore de lien personnalisé. <br /><br />
                  <v-btn small tile color="primary" @click="addNewMenuItem">
                    <v-icon left>mdi-plus</v-icon>
                    Ajouter un lien
                  </v-btn>
                </td>
              </tr>
              <tr v-for="menuItem in sortedCustomMenuItems" :key="menuItem.id">
                <td>
                  <v-btn icon small @click="moveMenuItemUp(menuItem)">
                    <v-icon small>mdi-arrow-up</v-icon>
                  </v-btn>
                  <v-btn icon small @click="moveMenuItemDown(menuItem)">
                    <v-icon small>mdi-arrow-down</v-icon>
                  </v-btn>
                </td>
                <td>{{ menuItem.label.fr }}</td>
                <td>{{ menuItem.url }}</td>
                <td class="text-center">
                  <v-btn icon small @click="editMenuItem(menuItem)">
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon small @click="deleteCustomMenuItem(menuItem)">
                    <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-btn
          v-if="!addCustomMenuItem && !!myCompany.booker.customMenuItems.length"
          small
          tile
          color="primary"
          block
          @click="addNewMenuItem"
        >
          <v-icon left>mdi-plus</v-icon>
          Ajouter un lien
        </v-btn>
        <div
          v-if="addCustomMenuItem"
          style="padding: 16px; border: 1px solid #cccccc"
        >
          <v-alert outlined type="error" v-if="customMenuError">
            {{ customMenuError }}
          </v-alert>
          <multilang-text-field
            v-model="customMenuLabel"
            label="Titre du menu"
            outlined
            dense
            class="mb-2"
          />
          <v-text-field
            v-model="customMenuUrl"
            label="Lien du menu"
            outlined
            dense
          />
          <div class="d-flex align-center">
            <v-btn text tile @click="cancelAddNewMenuItem" class="mr-3">
              Annuler
            </v-btn>
            <v-btn color="primary" tile @click="applyAddNewMenuItem">
              Valider
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row align="stretch">
      <v-col cols="12 h4-content">
        <h4 class="d-flex align-center">
          {{ $t('settings.siteSettings.siteTitle') }}
          <span
            v-if="myCompany.booker.favicon && !myCompany.booker.favicon.title"
            style="width: 15px; height: 15px"
            class="mt-0 ml-5 red rounded-circle"
          />
        </h4>
        <span
          :class="{
            red: myCompany.booker.favicon && !myCompany.booker.favicon.title,
          }"
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          outlined
          v-model="myCompany.booker.favicon.title"
          :class="{
            empty: myCompany.booker.favicon && !myCompany.booker.favicon.title,
          }"
        />
        <v-text-field
          outlined
          v-model="myCompany.booker.seo.siteTabTitlePattern"
          label="Pattern du titre du site (SEO)"
        />
        <v-textarea
          outlined
          v-model="myCompany.booker.seo.siteMetaDescription"
          label="Meta description du site (SEO)"
        />
      </v-col>
    </v-row>

    <v-row align="stretch">
      <v-col cols="12 h4-content">
        <h4 class="d-flex align-center">
          {{ $t('settings.siteSettings.siteFavicon') }}
          <span
            v-if="favicon && !favicon.length"
            style="width: 15px; height: 15px"
            class="mt-0 ml-5 red rounded-circle"
          />
        </h4>
        <span :class="{ red: favicon && !favicon.length }" />
      </v-col>
      <v-col cols="12">
        <images-selector
          v-model="favicon"
          single
          height="150px"
          width="150px"
          :size="[150, 150]"
        />
      </v-col>
    </v-row>

    <v-row align="stretch">
      <v-col cols="12 h4-content">
        <h4 class="d-flex align-center">
          Logo
          <span
            v-if="logo && logo.length && myCompany.identity.logoSize === 75"
            style="width: 15px; height: 15px"
            class="mt-0 ml-5 red rounded-circle"
          />
        </h4>
        <span
          :class="{
            red: logo && logo.length && myCompany.identity.logoSize === 75,
          }"
        />
      </v-col>
      <v-col cols="12">
        <images-selector
          v-model="logo"
          single
          :size="[300, 300]"
          title="Logo"
        />
        <v-select
          outlined
          :label="$t('settings.identity.logoSize')"
          v-model="myCompany.identity.logoSize"
          :items="[
            { value: 50, text: $t('settings.identity.small') },
            { value: 100, text: $t('settings.identity.medium') },
            { value: 150, text: $t('settings.identity.big') },
          ]"
          :class="{
            empty: logo && logo.length && myCompany.identity.logoSize === 75,
          }"
          :disabled="logo && !logo.length"
        >
          <template v-slot:selection="{ item }">
            {{ item.text }}
            {{
              '(' + $t('settings.identity.height') + ' : ' + item.value + 'px)'
            }}
          </template>
          <template v-slot:item="{ item }">
            {{ item.text }}
            {{
              '(' + $t('settings.identity.height') + ' : ' + item.value + 'px)'
            }}
          </template>
        </v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue'
import RichEditor from '../helpers/richEditor'
import { langs } from '@/helpers/langs'
import MultilangTextField from '../helpers/multilangTextField'
import swal from 'sweetalert'
import ImagesSelector from '../helpers/imagesSelector'

export default {
  name: 'site',
  components: {
    ImagesSelector,
    MultilangTextField,
    RichEditor,
  },
  props: {
    myCompany: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      langs,
      currencyOptions: [
        { value: { symbol: '€', code: 'EUR' }, text: 'EUR (€)' },
        { value: { symbol: '$', code: 'USD' }, text: 'DOLLAR ($)' },
        { value: { symbol: 'CHF', code: 'CHF' }, text: 'Franc Suisse (CHF)' },
        { value: { symbol: 'CFP', code: 'CFP' }, text: 'Franc CFP (CFP)' },
      ],
      currencyFormatOptions: [
        { value: '#!', text: '10.00€' },
        { value: '# !', text: '10.00 €' },
        { value: '!#', text: '€10.00' },
        { value: '! #', text: '€ 10.00' },
      ],
      listIcons: [
        'mdi-home',
        'mdi-gift',
        'mdi-spa',
        'mdi-phone',
        'mdi-store',
        'mdi-basket',
        'mdi-account',
        'mdi-post',
      ],
      favicon: null,
      tabNav: 0,
      logo: [],
      popupImg: [],
      addCustomMenuItem: false,
      customMenuLabel: {},
      customMenuUrl: '',
      sortedCustomMenuItems: [],
      editCustomMenuItem: null,
      customMenuError: '',
    }
  },
  computed: {
    bottomBarActions() {
      return [
        {
          name: 'services',
          title: this.$t('settings.siteSettings.ourServices'),
          icon: 'mdi-spa',
        },
        {
          name: 'gift',
          title: this.$t('settings.siteSettings.gift'),
          icon: 'mdi-gift',
        },
        {
          name: 'contact',
          title: this.$t('settings.siteSettings.contact'),
          icon: 'mdi-phone',
        },
        {
          name: 'shops',
          title: this.$t('settings.siteSettings.ourShops'),
          icon: 'mdi-store',
        },
        {
          name: 'eShop',
          title: this.$t('settings.appearance.shop'),
          icon: 'mdi-basket',
        },
        {
          name: 'account',
          title: this.$t('settings.siteSettings.myAccount'),
          icon: 'mdi-account',
        },
        {
          name: 'magazine',
          title: this.$t('settings.siteSettings.magazine'),
          icon: 'mdi-post',
        },
      ]
    },
    isDisabledLeft() {
      if (
        this.myCompany.booker.navigation.mobile.bottomNavLeftInfo.name ===
          'gift' &&
        !this.myCompany.booker.gift
      ) {
        return true
      } else
        return (
          this.myCompany.booker.navigation.mobile.bottomNavLeftInfo.name ===
            'eShop' && !this.myCompany.booker.products.active
        )
    },
    isDisabledRight() {
      if (
        this.myCompany.booker.navigation.mobile.bottomNavRightInfo.name ===
          'gift' &&
        !this.myCompany.booker.gift
      ) {
        return true
      } else
        return (
          this.myCompany.booker.navigation.mobile.bottomNavRightInfo.name ===
            'eShop' && !this.myCompany.booker.products.active
        )
    },
  },
  created() {
    this.initSite()
  },
  methods: {
    addNewMenuItem() {
      this.addCustomMenuItem = true
      this.editCustomMenuItem = null
      this.customMenuLabel = ''
      this.customMenuUrl = ''
    },
    editMenuItem(item) {
      this.addCustomMenuItem = true
      this.editCustomMenuItem = item
      this.customMenuLabel = item.label
      this.customMenuUrl = item.url
    },
    cancelAddNewMenuItem() {
      this.addCustomMenuItem = false
    },
    applyAddNewMenuItem() {
      this.customMenuError = ''

      let labelEmpty = true

      Object.keys(this.customMenuLabel).forEach((lang) => {
        if (this.customMenuLabel[lang] || this.customMenuLabel[lang].trim()) {
          labelEmpty = false
        }
      })

      if (labelEmpty) {
        this.customMenuError = 'Veuillez entrer un titre.'
        return
      }
      if (!this.customMenuUrl || !this.customMenuUrl.trim()) {
        this.customMenuError = 'Veuillez entrer une URL.'
        return
      }

      this.addCustomMenuItem = false

      if (this.editCustomMenuItem) {
        const ind = this.myCompany.booker.customMenuItems.findIndex(
          (m) => m.id === this.editCustomMenuItem.id
        )

        this.myCompany.booker.customMenuItems[ind] = {
          id: this.editCustomMenuItem.id,
          label: this.customMenuLabel,
          url: this.customMenuUrl,
          order: this.editCustomMenuItem.order,
        }

        this.myCompany.booker.customMenuItems = JSON.parse(
          JSON.stringify(this.myCompany.booker.customMenuItems)
        )
      } else {
        this.myCompany.booker.customMenuItems.push({
          id: Date.now(),
          label: this.customMenuLabel,
          url: this.customMenuUrl,
          order: this.myCompany.booker.customMenuItems.length,
        })
      }

      this.sortedCustomMenuItems = JSON.parse(
        JSON.stringify(this.myCompany.booker.customMenuItems)
      ).sort((a, b) => a.order - b.order)
    },
    deleteCustomMenuItem(item) {
      this.myCompany.booker.customMenuItems =
        this.myCompany.booker.customMenuItems.filter((m) => m.id !== item.id)
      this.sortedCustomMenuItems = JSON.parse(
        JSON.stringify(this.myCompany.booker.customMenuItems)
      )
        .sort((a, b) => a.order - b.order)
        .map((m, i) => ({
          ...m,
          order: i,
        }))
      this.myCompany.booker.customMenuItems = JSON.parse(
        JSON.stringify(this.sortedCustomMenuItems)
      )
    },
    moveMenuItemUp(item) {
      this.sortedCustomMenuItems = JSON.parse(
        JSON.stringify(this.myCompany.booker.customMenuItems)
      ).sort((a, b) => a.order - b.order)

      const itemPosition = this.sortedCustomMenuItems.findIndex(
        (i) => i.id === item.id
      )

      if (itemPosition <= 0) {
        return
      }

      const prevItem = this.sortedCustomMenuItems[itemPosition - 1]
      const tmp = prevItem.order

      this.sortedCustomMenuItems[itemPosition - 1].order = item.order
      this.sortedCustomMenuItems[itemPosition].order = tmp

      this.myCompany.booker.customMenuItems = JSON.parse(
        JSON.stringify(this.sortedCustomMenuItems)
      )

      this.sortedCustomMenuItems = JSON.parse(
        JSON.stringify(this.myCompany.booker.customMenuItems)
      ).sort((a, b) => a.order - b.order)
    },
    moveMenuItemDown(item) {
      const itemPosition = this.sortedCustomMenuItems.findIndex(
        (i) => i.id === item.id
      )

      if (itemPosition >= this.myCompany.booker.customMenuItems.length - 1) {
        return
      }

      if (this.myCompany.booker.customMenuItems.length < 2) {
        return
      }

      const nextItem = this.sortedCustomMenuItems[itemPosition + 1]
      const tmp = nextItem.order

      this.sortedCustomMenuItems[itemPosition + 1].order = item.order
      this.sortedCustomMenuItems[itemPosition].order = tmp

      this.myCompany.booker.customMenuItems = JSON.parse(
        JSON.stringify(this.sortedCustomMenuItems)
      )

      this.sortedCustomMenuItems = JSON.parse(
        JSON.stringify(this.myCompany.booker.customMenuItems)
      ).sort((a, b) => a.order - b.order)
    },
    initSite() {
      if (!this.myCompany.booker) {
        Vue.set(this.myCompany, 'booker', {})
      }

      if (typeof this.myCompany.booker.customMenuItems === 'undefined') {
        Vue.set(this.myCompany.booker, 'customMenuItems', [])
      }

      this.sortedCustomMenuItems = JSON.parse(
        JSON.stringify(this.myCompany.booker.customMenuItems)
      ).sort((a, b) => a.order - b.order)

      if (typeof this.myCompany.booker.siteDisabled === 'undefined') {
        Vue.set(this.myCompany.booker, 'siteDisabled', false)
      }

      if (typeof this.myCompany.booker.generalMessage === 'undefined') {
        Vue.set(this.myCompany.booker, 'generalMessage', '')
      }

      if (typeof this.myCompany.booker.primarySiteLinkEnabled === 'undefined') {
        Vue.set(this.myCompany.booker, 'primarySiteLinkEnabled', false)
      }

      if (typeof this.myCompany.booker.primarySiteLinkText === 'undefined') {
        Vue.set(this.myCompany.booker, 'primarySiteLinkText', {
          fr: 'Retour au site',
        })
      }

      if (typeof this.myCompany.booker.primarySiteLink === 'undefined') {
        Vue.set(this.myCompany.booker, 'primarySiteLink', '')
      }

      if (!this.myCompany.booker.siteDisabledMessage) {
        Vue.set(this.myCompany.booker, 'siteDisabledMessage', '')
      }

      if (!this.myCompany.booker.langs) {
        Vue.set(this.myCompany.booker, 'langs', {})

        Object.keys(this.langs).forEach((lang) => {
          if (lang === 'fr') {
            Vue.set(this.myCompany.booker.langs, lang, true)
          } else {
            Vue.set(this.myCompany.booker.langs, lang, false)
          }
        })
      }

      if (!this.myCompany.booker.defaultLang) {
        Vue.set(this.myCompany.booker, 'defaultLang', 'fr')
      }

      if (!this.myCompany.booker.mCurrency) {
        Vue.set(this.myCompany.booker, 'mCurrency', {
          code: 'EUR',
          symbol: '€',
        })
      }

      if (!this.myCompany.booker.mCurrencyFormat) {
        Vue.set(this.myCompany.booker, 'mCurrencyFormat', '#!')
      }

      if (!this.myCompany.booker.navigation) {
        Vue.set(this.myCompany.booker, 'navigation', {})
      }
      if (!this.myCompany.booker.navigation.mobile) {
        Vue.set(this.myCompany.booker.navigation, 'mobile', {})
      }
      if (!this.myCompany.booker.navigation.mobile.bottomNavLeftInfo) {
        Vue.set(this.myCompany.booker.navigation.mobile, 'bottomNavLeftInfo', {
          name: 'services',

          title: { fr: this.$t('settings.siteSettings.ourServices') },
          icon: 'mdi-spa',
        })
      }
      if (!this.myCompany.booker.navigation.mobile.bottomNavRightInfo) {
        Vue.set(this.myCompany.booker.navigation.mobile, 'bottomNavRightInfo', {
          name: 'contact',
          title: { fr: this.$t('settings.siteSettings.contact') },
          icon: 'mdi-phone',
        })
      }

      if (!this.myCompany.booker.navigation.desktop) {
        Vue.set(this.myCompany.booker.navigation, 'desktop', {
          services: { fr: this.$t('settings.siteSettings.ourServices') },
          boutique: { fr: this.$t('settings.siteSettings.shop') },
          gift: { fr: this.$t('settings.siteSettings.gift') },
          magazine: { fr: this.$t('settings.siteSettings.magazine') },
          contact: { fr: this.$t('settings.siteSettings.ourShops') },
        })
      }

      if (!this.myCompany.booker.favicon) {
        Vue.set(this.myCompany.booker, 'favicon', {})
      }
      if (!this.myCompany.booker.favicon.image) {
        Vue.set(this.myCompany.booker.favicon, 'image', {
          url: 'https://ik.imagekit.io/tiwpkbgdgfs/_banqueimages/favicon/SB.png',
        })
      }
      if (!this.myCompany.booker.favicon.title) {
        Vue.set(
          this.myCompany.booker.favicon,
          'title',
          this.myCompany.identity.displayName
        )
      }
      this.favicon = this.myCompany.booker.favicon.image || null
      this.favicon = this.favicon ? [this.favicon] : []

      if (!this.myCompany.booker.products) {
        Vue.set(this.myCompany.booker, 'products', {
          active: false,
          list: {
            display: {
              price: true,
              duration: true,
            },
          },
        })
      }

      if (typeof this.myCompany.booker.products.active === 'undefined') {
        Vue.set(this.myCompany.booker.products, 'active', false)
      }

      if (typeof this.myCompany.booker.seo === 'undefined') {
        Vue.set(this.myCompany.booker, 'seo', {})
      }
      if (
        typeof this.myCompany.booker.seo.siteTabTitlePattern === 'undefined'
      ) {
        Vue.set(this.myCompany.booker.seo, 'siteTabTitlePattern', '%s')
      }
      if (
        typeof this.myCompany.booker.seo.siteMetaDescription === 'undefined'
      ) {
        Vue.set(this.myCompany.booker.seo, 'siteMetaDescription', '')
      }

      if (!this.myCompany.identity.logo) {
        Vue.set(this.myCompany.identity, 'logo', null)
      }

      if (!this.myCompany.identity.logoSize) {
        Vue.set(this.myCompany.identity, 'logoSize', 75)
      }

      this.logo = this.myCompany.identity.logo || null
      this.logo = this.logo ? [this.logo] : []

      this.popupImg = this.myCompany.booker.popupImg || null
      this.popupImg = this.popupImg ? [this.popupImg] : []
    },
    changeBottomLeft(val) {
      const tab = this.bottomBarActions.find((tab) => tab.name === val)
      this.myCompany.booker.navigation.mobile.bottomNavLeftInfo.title.fr =
        tab.title
      this.myCompany.booker.navigation.mobile.bottomNavLeftInfo.icon = tab.icon
    },
    changeBottomRight(val) {
      const tab = this.bottomBarActions.find((tab) => tab.name === val)
      this.myCompany.booker.navigation.mobile.bottomNavRightInfo.title.fr =
        tab.title
      this.myCompany.booker.navigation.mobile.bottomNavRightInfo.icon = tab.icon
    },
    resetMobile() {
      swal({
        title: this.$t('settings.siteSettings.areYouSureReset'),
        text: '',
        icon: 'warning',
        buttons: [
          this.$t('icons.cancel'),
          this.$t('settings.siteSettings.reset'),
        ],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.myCompany.booker.navigation.mobile.bottomNavLeftInfo = {
            name: 'services',
            title: { fr: this.$t('settings.siteSettings.ourServices') },
            icon: 'mdi-spa',
          }
          this.myCompany.booker.navigation.mobile.bottomNavRightInfo = {
            name: 'contact',
            title: { fr: this.$t('settings.siteSettings.contact') },
            icon: 'mdi-phone',
          }
        }
      })
    },
    resetDesktop() {
      swal({
        title: this.$t('settings.siteSettings.areYouSureReset'),
        text: '',
        icon: 'warning',
        buttons: [
          this.$t('icons.cancel'),
          this.$t('settings.siteSettings.reset'),
        ],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.myCompany.booker.navigation.desktop = {
            services: { fr: this.$t('settings.siteSettings.ourServices') },
            boutique: { fr: this.$t('settings.siteSettings.shop') },
            gift: { fr: this.$t('settings.siteSettings.gift') },
            magazine: { fr: this.$t('settings.siteSettings.magazine') },
            contact: { fr: this.$t('settings.siteSettings.ourShops') },
          }
        }
      })
    },
  },
  watch: {
    'myCompany.booker.gift'() {
      if (
        !this.myCompany.booker.gift &&
        this.myCompany.booker.navigation.mobile.bottomNavLeftInfo.name ===
          'gift'
      ) {
        this.myCompany.booker.navigation.mobile.bottomNavLeftInfo = {
          name: 'services',
          title: { fr: this.$t('settings.siteSettings.ourServices') },
          icon: 'mdi-spa',
        }
      }
      if (
        !this.myCompany.booker.gift &&
        this.myCompany.booker.navigation.mobile.bottomNavRightInfo.name ===
          'gift'
      ) {
        this.myCompany.booker.navigation.mobile.bottomNavRightInfo = {
          name: 'contact',
          title: { fr: this.$t('settings.siteSettings.contact') },
          icon: 'mdi-phone',
        }
      }
    },
    'myCompany.booker.products.active'() {
      if (
        !this.myCompany.booker.products.active &&
        this.myCompany.booker.navigation.mobile.bottomNavLeftInfo.name ===
          'eShop'
      ) {
        this.myCompany.booker.navigation.mobile.bottomNavLeftInfo = {
          name: 'services',
          title: { fr: this.$t('settings.siteSettings.ourServices') },
          icon: 'mdi-spa',
        }
      }
      if (
        !this.myCompany.booker.products.active &&
        this.myCompany.booker.navigation.mobile.bottomNavRightInfo.name ===
          'eShop'
      ) {
        this.myCompany.booker.navigation.mobile.bottomNavRightInfo = {
          name: 'contact',
          title: { fr: this.$t('settings.siteSettings.contact') },
          icon: 'mdi-phone',
        }
      }
    },
    favicon() {
      this.$emit('changeFavicon', this.favicon)
    },
    logo() {
      this.$emit('changeLogo', this.logo)
    },
    popupImg() {
      this.$emit('changePopupImg', this.popupImg)
    },
  },
}
</script>

<style scoped>
.notSelectedTab {
  background-color: #cbba95aa !important;
}
.notSelectedTab:hover {
  background-color: #cbba95 !important;
}
</style>
