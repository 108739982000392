import i18n from '../i18n'

export default function () {
  return {
    templates: [
      {
        id: 1,
        identifier: 'legal',
        title: i18n.t('customMails.legalNotice'),
        vars: [
          // {
          //   id: 1,
          //   name: i18n.t('customMails.companyName'),
          //   value: 'COMPANY_NAME',
          //   displayValue: 'NOM_ENTREPRISE'
          // }
        ],
        body: {
          fr: `<p>{{COMPANY_NAME}}</p>`,
        },
      },
      {
        id: 2,
        identifier: 'userForgetPassword',
        title: i18n.t('customMails.forgotPassword'),
        vars: [
          {
            id: 1,
            name: i18n.t('customMails.resetUrl'),
            value: 'LINKURL',
            displayValue: 'URL_ACTIVATION',
          },
          {
            id: 2,
            name: i18n.t('customMails.resetLink'),
            value: 'LINK',
            displayValue: 'LIEN_ACTIVATION',
          },
          {
            id: 3,
            name: i18n.t('other.firstName'),
            value: 'FIRSTNAME',
            displayValue: 'PRENOM',
          },
          {
            id: 4,
            name: i18n.t('other.name'),
            value: 'LASTNAME',
            displayValue: 'NOM',
          },
        ],
        body: {
          fr: `<p>Bonjour {{LASTNAME}} {{FIRSTNAME}},</p><p>Vous avez demandé une réinitialisation de votre mot de passe.</p><p>Pour ce faire, veuillez cliquer sur ce lien :</p><p>{{LINK}}</p><p>Si vous n'êtes pas à l'origine de cette demande, veuillez ne pas prendre en compte cet email.</p><p>Cordialement.</p>`,
        },
      },
      {
        id: 3,
        identifier: 'newBooking',
        title: i18n.t('customMails.reservationConfirmation'),
        vars: [
          {
            id: 1,
            name: i18n.t('customMails.reservationSummary'),
            value: 'BOOKINGS',
            displayValue: 'COMMANDE',
          },
          {
            id: 2,
            name: i18n.t('customMails.dateAndHour'),
            value: 'ARRIVAL',
            displayValue: 'ARRIVEE',
          },
          {
            id: 3,
            name: i18n.t('customMails.reservationTotal'),
            value: 'TOTAL',
            displayValue: 'TOTAL',
          },
          {
            id: 4,
            name: i18n.t('customMails.customerFirstName'),
            value: 'FIRSTNAME',
            displayValue: 'FIRSTNAME',
          },
          {
            id: 5,
            name: i18n.t('customMails.customerName'),
            value: 'LASTNAME',
            displayValue: 'LASTNAME',
          },
          {
            id: 6,
            name: i18n.t('customMails.serviceProvider'),
            value: 'PRESTATAIRE',
            displayValue: 'PRESTATAIRE',
          },
          {
            id: 7,
            name: i18n.t('customMails.providerAddress'),
            value: 'PRESTATAIRE_ADDRESS',
            displayValue: 'PRESTATAIRE_ADDRESS',
          },
          {
            id: 8,
            name: i18n.t('customMails.orderNumber'),
            value: 'AB_BOOKING_ID',
            displayValue: 'AB_BOOKING_ID',
          },
        ],
        body: {
          fr: `<h1 style="color:#5cc576;font-size:23px;text-align:center;"><strong>Réservation confirmée</strong></h1><p>Bonjour {{LASTNAME}} {{FIRSTNAME}},</p><p>Nous vous confirmons votre réservation du {{ARRIVAL}}.</p><p>Voici le récapitulatif :</p><p>{{BOOKINGS}}</p><p><strong>Total: </strong> {{TOTAL}}</p><p>A bientôt !</p><p>Cordialement.</p>`,
        },
      },
      {
        id: 4,
        identifier: 'registrationCode',
        title: i18n.t('customMails.verificationCode'),
        vars: [
          {
            id: 1,
            name: i18n.t('customMails.confirmationCode'),
            value: 'CODE',
            displayValue: 'CODE',
          },
        ],
        body: {
          fr: `<p>Bonjour,</p><p>Voici votre code de vérification :</p><p><strong>{{CODE}}</strong></p><p>Cordialement.</p>`,
        },
      },
      {
        id: 5,
        identifier: 'newRegistration',
        title: i18n.t('customMails.newRegistration'),
        vars: [
          {
            id: 1,
            name: i18n.t('customMails.customerFirstName'),
            value: 'PRENOM',
            displayValue: 'PRENOM',
          },
          {
            id: 2,
            name: i18n.t('customMails.customerName'),
            value: 'NOM',
            displayValue: 'NOM',
          },
        ],
        body: {
          fr: `<p>Bonjour {{NOM}} {{PRENOM}},</p><p>Votre compte a bien été créé.</p><p>Vous pouvez dès à présent vous connecter sur votre espace client.</p><p>Merci et à bientôt !</p><p>Cordialement.</p>`,
        },
      },
      {
        id: 6,
        identifier: 'canceledBooking',
        title: i18n.t('customMails.reservationCancellation'),
        vars: [
          {
            id: 1,
            name: i18n.t('customMails.dateAndHour'),
            value: 'ARRIVAL',
            displayValue: 'ARRIVEE',
          },
          {
            id: 2,
            name: i18n.t('customMails.customerFirstName'),
            value: 'FIRSTNAME',
            displayValue: 'FIRSTNAME',
          },
          {
            id: 3,
            name: i18n.t('customMails.customerName'),
            value: 'LASTNAME',
            displayValue: 'LASTNAME',
          },
        ],
        body: {
          fr: `<p>Bonjour {{LASTNAME}} {{FIRSTNAME}},</p><p>Votre réservation du {{ARRIVAL}} n'a pas été effectuée.</p><p>Vous n'avez pas été prélevé.</p><p>Cordialement.</p>`,
        },
      },
      {
        id: 7,
        identifier: 'userCanceledBooking',
        title: i18n.t('customMails.reservationCancellationByUser'),
        vars: [
          {
            id: 1,
            name: i18n.t('customMails.dateAndHour'),
            value: 'ARRIVAL',
            displayValue: 'ARRIVEE',
          },
          {
            id: 2,
            name: i18n.t('customMails.customerFirstName'),
            value: 'FIRSTNAME',
            displayValue: 'FIRSTNAME',
          },
          {
            id: 3,
            name: i18n.t('customMails.customerName'),
            value: 'LASTNAME',
            displayValue: 'LASTNAME',
          },
        ],
        body: {
          fr: `<p>Bonjour {{LASTNAME}} {{FIRSTNAME}},</p><p>Votre réservation du {{ARRIVAL}} a bien été annulée.</p><p>Cordialement.</p>`,
        },
      },
      {
        id: 8,
        identifier: 'newgift',
        title: i18n.t('customMails.gift'),
        vars: [
          // {
          //   id: 1,
          //   name: "Nom complet de l'acheteur",
          //   value: 'BUYER_NAME',
          //   displayValue: 'NOM_ACHETEUR',
          // },
          {
            id: 2,
            name: i18n.t('customMails.customerFirstName'),
            value: 'FIRSTNAME',
            displayValue: 'FIRSTNAME',
          },
          {
            id: 3,
            name: i18n.t('customMails.customerName'),
            value: 'LASTNAME',
            displayValue: 'LASTNAME',
          },
          {
            id: 4,
            name: i18n.t('customMails.details'),
            value: 'DETAILS',
            displayValue: 'DETAILS',
          },
          {
            id: 5,
            name: i18n.t('customMails.message'),
            value: 'MESSAGE',
            displayValue: 'MESSAGE',
          },
          {
            id: 6,
            name: i18n.t('customMails.alertMessage'),
            value: 'COPY',
            displayValue: 'COPY',
          },
        ],
        body: {
          fr: `<p>Bonjour {{LASTNAME}} {{FIRSTNAME}},</p><p>{{COPY}}{{IMG_URL}}</p><p>{{BUYER_NAME}} vient de vous offrir un bon cadeau !</p><p>{{DETAILS}}</p><p>{{BUYER_NAME}} vous a laissé le message suivant :</p><p>{{MESSAGE}}</p><p>Cordialement.</p>`,
        },
      },
    ],
    getTemplates() {
      return this.templates
    },
  }
}
